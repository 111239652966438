import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './examples/landing/landing.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { LoginComponent } from './pages/login/login.component';
import { MainModule } from './pages/main/main.module';
import { MainRoutingModule } from './pages/main/main.routing';
import { MainComponent } from './pages/main/main.component';
import { HomeComponent } from './pages/home/home.component';
import { ComponentsModule } from './components/components.module';




@NgModule({
    declarations: [
        MainComponent,
        
    ],
    imports: [
       MainRoutingModule,
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
